<template>
  <div
    v-if="CompanyState.hasLoadedOnce"
    class="esync_full-width"
  >
    <div class="table-holder">
      <div class="top-bar">
        <b-form-input
          v-model="searchPhrase"
          class="search-bar"
          type="search"
          :placeholder="$t('Components.Admin.placeholder_search')"
          @update="updateTable()"
        />
        <Button
          class="action-btn sync-all"
          :size="'tiny'"
        >
          <svg-icon src="/icons/sync.svg" />
          {{ $t('Components.Admin.sync_all') }}
        </Button>
      </div>
      <div class="info-bar">
        <div class="status-indicators">
          <div class="indicator synced" />
          <p> {{ syncedStatus }} </p>
          <div class="indicator not-synced" />
          <p> {{ notSyncedStatus }} </p>
          <div class="indicator failed" />
          <p> {{ failedStatus }} </p>
        </div>
        <Button
          class="action-btn round"
          :size="'tiny'"
          :disabled="isLoading"
          @click="update()"
        >
          <svg-icon src="/icons/refresh.svg" />
          <!-- Update -->
        </Button>
      </div>
      <b-table
        v-if="!isLoading"
        sort-icon-left
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :reactive="true"
        striped
        class="table"
        outlined
        :fields="fields"
        :items="currentUsers"
      >
        <template
          #cell(username)="data"
        >
          <div class="user center-data">
            <PersonAvatar
              class="avatar-icon"
              height="50px"
              width="50px"
              :src="data.item.icon"
              :border="false"
            />
            <div class="user-info">
              <p> {{ data.item.name }} </p>
              <p class="small-regular">
                {{ data.item.username }}
              </p>
            </div>
          </div>
        </template>

        <template
          #cell(status)="data"
        >
          <div
            class="status center-data"
          >
            <Label
              :status="parseStatus(data.item.status)"
              :text="statusToText(data.item.status)"
            />
          </div>
        </template>

        <template
          #cell(subcreated)="data"
          class="user"
        >
          <p class="small-regular center-data">
            {{ data.item.subcreated }}
          </p>
        </template>

        <template #cell(synclast)="data">
          <p class="small-regular center-data">
            {{ data.item.synclast }}
          </p>
        </template>

        <template #cell(subexpires)="data">
          <p class="small-regular center-data">
            {{ data.item.subexpires }}
          </p>
        </template>

        <template
          #cell()="row"
        >
          <div
            class="actions center-data"
          >
            <Button
              v-if="row.item.subscriptionid || row.item.error"
              v-model="row._showDetails"
              class="action-btn round"
              :size="'tiny'"
              @click="row.toggleDetails"
            >
              <font-awesome-icon :icon="row.item._showDetails ? 'chevron-up' : 'chevron-down'" />
            </Button>
          </div>
        </template>

        <template
          #row-details="row"
        >
          <div class="user-details-item">
            <h6> {{ $t('Components.Admin.subscription_id') }} </h6>
            <p> {{ row.item.subscriptionid }} </p>
          </div>
          <div class="user-details-item">
            <h6> {{ $t('Components.Admin.user_id') }} </h6>
            <p> {{ row.item.userid }} </p>
          </div>
          <div class="user-details-item">
            <h6>
              {{ $t('Components.Admin.meta_data') }}
            </h6>
            <pre>
            <code>
             {{ beautify(row.item.metadata) }}
            </code>
          </pre>
          </div>
          <div
            v-if="row.item.error"
            class="user-details-item error"
          >
            <h6>
              {{ $t('Components.Admin.error') }}
            </h6>
            <p> {{ row.item.error }} </p>
          </div>
        </template>
      </b-table>
      <div
        v-else-if="isLoading"
        class="spinner spinner-medium"
      />
      <div
        v-if="error !== null"
        class="error-message"
      >
        <p>
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BFormInput } from 'bootstrap-vue';
import Button from '@/components/button/button.vue';
import CompanyState from '@/singletons/company.state.singleton';
// import { getSyncedUsers } from '@/util/api';
import Label from '@/components/label.vue';
import EsyncController from './esync.controller';

export default {
  components: {
    BTable,
    BFormInput,
    Button,
    Label,
  },
  data() {
    return {
      CompanyState,
      sortBy: 'user',
      sortDesc: false,
      searchPhrase: '',
      filteredUsers: [],
      isLoading: false,
      error: null,
      fields: [
        { key: 'username', label: 'User', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'subcreated', label: 'Subscription Created', sortable: true },
        { key: 'synclast', label: 'Last Sync', sortable: true },
        { key: 'subexpires', label: 'Subscription Expires', sortable: true },
        /* Virtual Column that does not exist in users */
        ' ', // Actions, empty to have no actual field name
      ],
      EsyncController,
    };
  },
  computed: {
    currentUsers() {
      return this.filteredUsers;
    },
    // SYNCED = 1
    syncedStatus() { return this.filteredUsers.filter((item) => item.status === 1).length; },
    // NOT_SYNCED = 2, EXPIRED = 3
    notSyncedStatus() { return this.filteredUsers.filter((item) => item.status === 2 || item.status === 3).length; },
    // ERROR = 0
    failedStatus() { return this.filteredUsers.filter((item) => item.status === 0).length; },
  },
  async mounted() {
    await this.update();
  },
  methods: {
    async update() {
      try {
        this.isLoading = true;
        await EsyncController.getSyncedUsers();
        this.filteredUsers = EsyncController.users;
        this.searchPhrase = '';
        this.error = null;
      } catch (e) {
        this.error = this.$t('Components.Admin.error_fetching_users');
      }
      this.isLoading = false;
    },
    beautify(string) {
      return JSON.parse(string);
    },
    parseStatus(status) {
      if (status === 1) return 'success';
      if (status === 2 || status === 3) return 'warning';
      if (status === 0) return 'danger';
      return null;
    },
    statusToText(status) {
      if (status === 1) return 'SYNCED';
      if (status === 2) return 'NOT SYNCED';
      if (status === 3) return 'EXPIRED';
      if (status === 0) return 'FAILED';
      return null;
    },
    updateTable() {
      const phrase = this.searchPhrase.toLowerCase();
      this.filteredUsers = EsyncController.users.filter((item) => item.username.toLowerCase().includes(phrase)
        || item.name?.toLowerCase().includes(phrase));
      if (this.filteredUsers.length === 0 && EsyncController.users.length !== 0) {
        this.error = this.$t('Components.Admin.no_search_results');
      } else if (EsyncController.users.length !== 0) {
        this.error = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .error-message {
    margin-top: 2rem;
  }
  // .show-more-btn {
  //   border-radius: 50% !important;
  //   background-color: white;
  //   border: 1px solid gray;
  //   width: 42px;
  //   height: 42px;
  //   color: black;
  // }
  .top-bar {
    display: inline-flex;
    width: 100%;
    margin: 5rem 0 1rem 0;
    gap:1rem;
    justify-content: space-between;
    .search-bar {
      width: 25rem;
    }
  }
  .info-bar{
    display: flex;
    justify-content: space-between;
    margin: auto 0;
  .status-indicators {
    display: flex;
    margin-top:1.5rem;
    margin-bottom: 0.5rem;
    p {
      margin: auto;
      margin-left: 0.3rem;
      margin-right: 1.5rem;
    }
    .indicator {
      margin: auto;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      &.synced {
        background-color: var(--primary-color);
      }
      &.not-synced {
        background-color: var(--away-color);
      }
      &.failed {
        background-color: var(--occupied-color);
      }
    }
  }
  }
  .action-btn {
    margin: auto 0;
    fill: white;
    height: 2.4rem;
    line-height: 1rem;
    &.sync-all {
      margin-left: auto;
    }
    &.round {
      fill: black;
      background-color: white;
      color: var(--primary-font-color);
      border: 1px solid var(--border-color);
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      // &:hover {
      //   background-color: var(--color-pastel-resource-free);
      // }
    }
  }
  .table-striped tbody tr:nth-of-type(odd){
    background-color: rgb(245, 245, 245) !important;
  }
  .user-details-item {
    display: block;
    margin: 0 0 2rem 1rem;
    p {
      margin: 0;
      padding: 0;
    }
    &.error {
      p, h6 {
        color: var(--toast--error);
      }
    }
  }
  .esync_full-width{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    .table-holder {
      width: 95%;
      max-width: 1800px;
    }
  }
  .table {
    td {
      vertical-align: middle;
      .center-data {
        display: flex;
        margin: auto 0;
      }
    }
    .user {
      display: flex;
      gap: 1rem;
      .avatar-icon{
        margin: auto 0;
      }
      .user-info {
        p {
          margin: auto 0;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    .actions {
      display: inline-flex;
    }
  }
</style>
