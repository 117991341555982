<template>
  <div
    v-if="CompanyState.hasLoadedOnce"
    class="device_full-width scrollable"
  >
    <div>
      <div class="table-holder">
        <b-table
          v-if="DeviceModel.devices"
          sort-icon-left
          :sort-by.sync="DeviceModel.orderBy"
          :sort-desc.sync="DeviceModel.orderDesc"
          :reactive="true"
          :sticky-header="true"
          striped
          class="table"
          outlined
          :fields="fields"
          :items="DeviceModel.devices"
          :no-local-sorting="true"
          @sort-changed="sortingChanged"
        >
          <template
            #cell(deviceid)="data"
          >
            <div class="device center-data">
              <DeviceTypeLabel
                :type="data.item.devicetype"
                :subtype="data.item.subdevicetype"
              />
              <div class="device-info">
                <p class="small-semi">
                  {{ data.item.deviceid }}
                </p>
                <div class="device_zonename">
                  <p class="tiny-semi">
                    {{ data.item.zonename }}
                  </p>
                  <p class="tiny-regular">
                    /{{ data.item.floorname }}
                  </p>
                  <p class="tiny-thin">
                    /{{ data.item.companyname }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template
            #cell(stoptime)="data"
          >
            <div
              class="status center-data"
            >
              <p class="small-regular center-data">
                {{ parseStopTime(data.item.stoptime) }}
              </p>
            </div>
          </template>

          <template
            #cell(heartbeat)="data"
            class="device"
          >
            <p class="small-regular center-data">
              <Label
                :status="getHeartbeatStatus(data.item.heartbeat)"
                :text="parseHeartbeat(data.item.heartbeat)"
              />
            </p>
          </template>
        </b-table>
        <div
          v-else-if="!DeviceModel.devices"
          class="animation shimmer fake_table"
        />
      </div>
    </div>
    <div class="pagination_container">
      <PaginationController
        v-model="DeviceModel.currentPage"
        :max-pages="maxPages"
        class="pagination"
        :allow-show-all="true"
        :show-all="isShowingAll"
        @show-all="showAll()"
        @prev="updateData()"
        @next="updateData()"
      />
    </div>
  </div>
</template>

<script>
import PaginationController from '@/components/pagination/pagination.controller.vue';
import { BTable } from 'bootstrap-vue';
import CompanyState from '@/singletons/company.state.singleton';
import DeviceModel from '@/singletons/device.state.singleton';
import Label from '@/components/label.vue';
import DeviceService from '@/Services/Device/device.service';
import { DateTime } from 'luxon';
import DeviceTypeLabel from './components/device.type.label.vue';

const DEFAULT_PER_PAGE = 15;

export default {
  components: {
    BTable,
    DeviceTypeLabel,
    PaginationController,
    Label,
  },
  data() {
    return {
      isShowingAll: false,
      CompanyState,
      DateTime,
      DeviceModel,
      fields: [
        {
          key: 'deviceid',
          label: 'Device',
          sortable: false,
        },
        {
          key: 'stoptime',
          label: 'Session',
          sortable: true,
        },
        {
          key: 'heartbeat',
          label: 'Last Heartbeat',
          sortable: true,
        },
      ],
      sortBy: null,
      sortDesc: false,
      debounceTimer: null,
    };
  },
  computed: {
    maxPages() { return Math.ceil(DeviceModel.numberOfDevices / DeviceModel.perPage) || 1; }, // maxPages should be min 1
  },
  created() {
    DeviceService.fetchFullDeviceCount();
    DeviceService.fetchDeviceCount();
    this.updateData();
  },
  methods: {
    showAll() {
      DeviceModel.currentPage = 1;
      this.isShowingAll = !this.isShowingAll;
      DeviceModel.perPage = this.isShowingAll ? DeviceModel.numberOfDevices : DEFAULT_PER_PAGE;
      this.updateData();
    },
    updateData() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;

      this.debounceTimer = setTimeout(() => {
        DeviceService.fetchDevices();
      }, 250);
    },
    getLocalTime(time) {
      const date = DateTime.fromISO(time);
      return `${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
    },
    getHeartbeatStatus(heartbeat) {
      if (!heartbeat) return 'danger';

      const date = DateTime.fromISO(heartbeat);
      const today = DateTime.now();
      const isToday = date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');
      if (!isToday) return 'danger';
      const millis30min = 30 * 60 * 1000;
      const differenceInMillis = today.toMillis() - date.toMillis();
      if (differenceInMillis > millis30min) return 'warning';
      return 'success';
    },
    parseStopTime(stoptime) {
      if (!stoptime) return '';
      const date = DateTime.fromISO(stoptime);
      const today = DateTime.now();

      const isToday = date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');
      const inFuture = date.toMillis() > today.toMillis();

      const timestring = date.toLocaleString(DateTime.TIME_SIMPLE);
      const datestring = date.toLocaleString(DateTime.DATE_SHORT);

      if (inFuture) {
        return `Active until ${timestring}`;
      } if (isToday) {
        return `Session ended ${timestring}`;
      }
      return `Last session ${datestring}`;
    },

    parseHeartbeat(heartbeat) {
      if (!heartbeat) return 'No Heartbeat';
      const date = DateTime.fromISO(heartbeat);
      const today = DateTime.now();

      const isToday = date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');
      const timestring = date.toLocaleString(DateTime.TIME_SIMPLE);
      const datestring = date.toLocaleString(DateTime.DATE_SHORT);

      if (isToday) {
        return `Today, ${timestring}`;
      }
      return datestring;
    },

    sortingChanged(ctx) {
      DeviceModel.orderBy = ctx.sortBy;
      DeviceModel.orderDesc = ctx.sortDesc;
      this.updateData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-striped tbody tr:nth-of-type(odd){
    background-color: rgb(245, 245, 245) !important;
  }
  .device-details-item {
    display: block;
    margin: 0 0 2rem 1rem;
    p {
      margin: 0;
      padding: 0;
    }
    &.error {
      p, h6 {
        color: var(--toast--error);
      }
    }
  }
  .device_full-width{
    width: 100%;
    // padding: 0 0.5rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    .table-holder {
      width: 100%;
    .fake_table{
      display: flex;
      max-width: 1800px;
      height: calc(100vh - 15rem);
      width: 100%;
      margin: 0 auto;
      }
    }
  }
  .table {
    max-width: 1800px;
    min-height: calc(100vh - 15rem);
    max-height: 9999vh;
    margin: auto;
     &.b-table-sticky-header{
      overflow-y: visible;
     }
    td {
      min-width: 50px;
      max-width: 100px;
      vertical-align: middle;
      .center-data {
        display: flex;
        margin: auto 0;
      }
    }
    .device {
      display: flex;
      gap: 1rem;
      .device_zonename{
        display: flex;
        p{
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .device-info {
        p {
          margin: auto 0;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

</style>
